<template>
  <div class="flex items-center justify-center">
    <div :class="classes" />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  size?: "xs" | "sm";
  color?: "g7-blue" | "white";
}

const props = withDefaults(defineProps<Props>(), {
  size: "sm",
  color: "g7-blue",
});

const classes = computed(() => {
  const size = {
    xs: "h-6 w-6 lg:w-8 lg:h-8 ",
    sm: "w-16 h-16 ",
  }[props.size];

  const color = {
    "g7-blue": "border-g7-blue",
    white: "border-white",
  }[props.color];

  return `${size} ${color} border-4 border-solid rounded-full animate-spin border-t-transparent`;
});
</script>
